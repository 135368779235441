import cookies from './util.cookies'
import db from './util.db'
import log from './util.log'
import objects from './util.objects'
import views from './util.views'
import './util.math'
import { RawLocation } from 'vue-router'
import { Indexed } from '@/d2admin/delegate'

declare global {
  interface Number {
    add(val: number): number
    sub(val: number): number
    mul(val: number): number
    div(val: number): number
    round(bit: number): number
    formatInt(bit: number): number
    formatWithKiloSymbol(): string
    formatSize(): string
  }
}

declare module util {
  interface objects {
    stripNil<T>(obj: T, stripFalse?: boolean, stripEmptyString?: boolean): T,
    stripField<T>(obj: T, ...fields: string[]): T,
    clear(obj: any): void,
    remove<T>(array: T[], predicate: T | number | ((item: T) => boolean)): T[],
    replace<T>(array: T[], predicate: T | number | ((item: T) => boolean), replacement: T): boolean,
    replaceOrPush<T>(array: T[], predicate: T | ((item: T) => boolean), replacement: T): T[],
    refill<T>(from: T[] | Indexed, to: T[] | Indexed): void,
    equalExceptNil<T>(value: T, other: T): boolean,
    diffIndex<S, T>(list1: S[], list2: T[],
                    keyProvider: (item: S | T) => string,
                    iterator: (lhs: S, rhs: T) => void): void,
    reactive<T>(obj: T, ...ignoreFields: string[]): T
  }

  interface views {
    openTab(vue: Vue, location: RawLocation): void,
    setPageTitle(title: string): void,
    openNewPage(url: string): void
  }
}

declare module util {
  interface util {
    cookies: any,
    db: any,
    log: any,
    objects: util.objects,
    views: util.views
  }
}

const util: util.util = {
  cookies,
  db,
  log,
  objects,
  views
}

export default util
