import { GridOptions, IServerSideDatasource, IServerSideGetRowsParams } from 'ag-grid-community'
import { FilterCondition, FilterType, GroupByRowRequest, RowRequest, SortType } from '@/module/graphql'
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { ExplorerType } from './explorer-type'
import LolthExplorer from './index.vue'
import { RowsPage, stripInvalidFilterCondition } from './supports'
import _ from 'lodash'
import util from '@/d2admin/libs/util'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { stripFilterViewDef } from '@/module/butler/butler-util'

export declare type ExplorerDataSourceArgs = {
  explorerType: ExplorerType
  gridOptions: GridOptions
  explorer?: LolthExplorer
  fixedFilterCondition?: FilterCondition
  ignoreForExpanding?: boolean
  extraQueryParameters?: { [key: string]: any }
  urlPrefix?: string
}

export class ExplorerDataSource<R> implements IServerSideDatasource {
  readonly explorerType: ExplorerType
  readonly gridOptions?: GridOptions
  readonly explorer?: LolthExplorer
  readonly fixedFilterCondition?: FilterCondition
  ignoreForExpanding?: boolean

  constructor({
    explorerType,
    gridOptions,
    explorer,
    fixedFilterCondition,
    ignoreForExpanding = false
  }: ExplorerDataSourceArgs) {
    this.explorerType = explorerType
    this.gridOptions = gridOptions
    this.explorer = explorer
    this.fixedFilterCondition = fixedFilterCondition
    this.ignoreForExpanding = ignoreForExpanding
  }

  getRows(params: IServerSideGetRowsParams): void {
    this.debounceQuery(params)
  }

  private debounceQuery = _.debounce((params: IServerSideGetRowsParams) => {
    const ds = this
    this.doQuery(params.request).then(response => {
      if (ds.checkErrors(response)) return
      if (!ds.gridOptions.api) {
        console.warn('AgGrid is destroyed or not init yet, skip loading data.')
        return
      }
      const rowsPage = ds.resolveResponse(response)

      // reform row["k.e.y"] to row.k,e.y
      rowsPage.rows?.forEach(row => {
        _.keys(row).forEach(field => {
          if (field.indexOf('.') <= 0) return
          const v = row[field]
          delete row[field]
          _.set(row, field, v)
        })
      })

      params.successCallback(rowsPage.rows, rowsPage.total)
      return rowsPage.rows
    }).then(rows => {
      // reset lazy field fetched flag
      ds.gridOptions.context.lazyFieldFetched.clear()
      ds.onDataLoaded(rows)

      if (ds.gridOptions.context.onDataLoaded) {
        if (_.isPlainObject(ds.gridOptions.context.onDataLoaded)) {
          _.values(ds.gridOptions.context.onDataLoaded).forEach((fn: (rows: any[]) => any) => fn(rows))
        } else {
          ds.gridOptions.context.onDataLoaded(rows)
        }
      }

      if (ds.gridOptions.context.onDataLoadedOnce) {
        if (_.isPlainObject(ds.gridOptions.context.onDataLoadedOnce)) {
          _.values(ds.gridOptions.context.onDataLoadedOnce).forEach((fn: (rows: any[]) => any) => fn(rows))
        } else {
          ds.gridOptions.context.onDataLoadedOnce(rows)
        }
        delete ds.gridOptions.context.onDataLoadedOnce
      }

      if (ds.gridOptions.context.keepSelectionOnPaging) {
        ds.explorer?.restoreSelection()
      } else {
        ds.explorer?.clearSelections()
      }
    })
  }, 100)

  doQuery(request: IServerSideGetRowsRequest): Promise<R> {
    throw new Error('buildQueryParameters() is not implemented.')
  }

  onDataLoaded(rows: any[]) {}

  checkErrors(response: R): boolean {
    throw new Error('checkErrors(response) is not implemented.')
  }

  resolveResponse(response: R): RowsPage {
    throw new Error('resolveResponse(response) is not implemented.')
  }

  isDoingGrouping(request: IServerSideGetRowsRequest) {
    // we are not doing grouping if at the lowest level
    return (request.rowGroupCols?.length || 0) !==
            (request.groupKeys?.length || 0)
  }

  public buildRowRequest(request: IServerSideGetRowsRequest): RowRequest {
    const rowRequest: RowRequest = {
      start: request.startRow,
      rows: this.gridOptions.paginationPageSize
    }
    if (this.explorer) {
      // master-grid, filterCondition is managed by itself, but need to strip invalid filterCondition first.
      const condition = this.explorer.mergedFilterCondition
      rowRequest.filter = _.cloneDeep(condition)
    } else {
      // detail-grid, filterCondition is collected by request, no customFilter available here
      if (!_.isEmpty(request.filterModel)) {
        rowRequest.filter = {
          filterType: FilterType.And,
          conditions: _.values(request.filterModel).filter(f => f)
        }
        if (this.fixedFilterCondition) {
          rowRequest.filter.conditions?.push(this.fixedFilterCondition)
        }
      } else if (this.fixedFilterCondition) {
        rowRequest.filter = this.fixedFilterCondition
      }
    }

    // convert rowGroup to where
    if (!_.isEmpty(request.rowGroupCols) && !_.isEmpty(request.groupKeys) &&
        request.rowGroupCols.length === request.groupKeys.length) {
      const expendGroupFilters: FilterCondition[] = []
      for (let i = 0; i < request.rowGroupCols.length; i++) {
        const colDef: ColDef = _.find(this.gridOptions.columnDefs,
          (colDef: ColDef) => colDef.field === request.rowGroupCols[i].id)
        const remapField = colDef.filterParams?.viewDef?.fieldKey
        if (request.groupKeys[i] !== '') {
          expendGroupFilters.push({
            filterType: FilterType.String,
            field: remapField || colDef.colId,
            operator: 'equals',
            value: request.groupKeys[i]
          })
        } else {
          expendGroupFilters.push({
            filterType: FilterType.IsNull,
            field: remapField || colDef.colId
          })
        }
      }
      if (rowRequest.filter) {
        expendGroupFilters.push(rowRequest.filter)
      }
      rowRequest.filter = {
        filterType: FilterType.And,
        conditions: expendGroupFilters
      }
    }

    stripInvalidFilterCondition(rowRequest.filter!)
    stripFilterViewDef(rowRequest.filter!)
    util.objects.stripField(rowRequest.filter, 'filterViewDef')

    if (!_.isEmpty(request.sortModel)) {
      rowRequest.sort = request.sortModel.map((sort: any) => {
        const colDef: ColDef = _.find(this.gridOptions.columnDefs,
          (colDef: ColDef) => colDef.field === sort.colId)
        const remapField = colDef.filterParams?.viewDef?.fieldKey
        return {
          field: remapField || sort.colId,
          sort: sort.sort === 'asc' ? SortType.Asc : SortType.Desc
        }
      })
    }
    return rowRequest
  }

  public buildGroupByRowRequest(request: IServerSideGetRowsRequest): GroupByRowRequest {
    const rowRequest = this.buildRowRequest(request) as GroupByRowRequest

    if (!_.isEmpty(request.rowGroupCols)) {
      rowRequest.groupByCols = request.rowGroupCols.map((col: any) => {
        const colDef: ColDef = _.find(this.gridOptions.columnDefs,
          (colDef: ColDef) => colDef.field === col.field)
        const remapField = colDef.filterParams?.viewDef?.fieldKey
        return {
          field: remapField || col.field
        }
      })
    }

    rowRequest.groupKeys = request.groupKeys
    if (!_.isEmpty(request.valueCols)) {
      rowRequest.aggCols = request.valueCols.map((col: any) => {
        const colDef: ColDef = _.find(this.gridOptions.columnDefs,
          (colDef: ColDef) => colDef.field === col.field)
        const remapField = colDef.filterParams?.viewDef?.fieldKey
        return {
          field: remapField || col.field,
          aggFunc: col.aggFunc
        }
      })
    }
    return rowRequest
  }
}
