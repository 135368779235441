import {
  FilterCondition,
  FilterType,
  MdAttribute,
  MdAttributeInput,
  MdData,
  MdDictionaryEntry,
  MdExtendData,
  MdField,
  MdTable,
  MdTableColumn,
  MdTableData,
  MdTableImportContext
} from '@/module/graphql'
import _ from 'lodash'
import { Maybe } from 'graphql/jsutils/Maybe'
import util from '@/d2admin/libs/util'
import { MdFieldTuple } from '@/module/master-data/types'
import Vue from 'vue'
import IcmpMdDataEditorDialog from '@/module/master-data/views/editor/icmp-md-data-editor-dialog.vue'
import IcmpMdDataDialog from '@/module/master-data/views/components/icmp-md-data-viewer-dialog.vue'
import { Indexed } from '@/d2admin/delegate'
import { FilterConditionView, FilterViewDef, FilterViewType } from '@/module/components/lolth-filter/types'
import IcmpMdExtendDetailPanel from '@/module/master-data/views/components/icmp-md-extend-detail-panel.vue'
import gql from 'graphql-tag'
import { ID } from '@/module/common/types'
import IcmpMdTableDataViewer from '@/module/master-data/views/table/icmp-md-table-data-viewer.vue'
import LocalDbDao from '@/module/common/local-db-dao'
import { APP_REALM_CODE } from '@/module/common/constants'

/******************************************************************************/
/* MdField / MdAttribute */
/******************************************************************************/

// MdAttributeInput.value格式, 无需转
// 文字, 数字, 是否, 日期, 扩展
// 附件: string[] { name, path } no-dump
// 组合: MdAttribute[] no-dump

// MdAttributeInput.value格式, 需要转
// 选项表: MdDictionaryEntry | number <--> MdDictionaryEntry.id: number + extra no-dump
// 多选选项表: MdDictionaryEntry[] | number[] <--> MdDictionaryEntry.ids: number[] + extra
// 判断value类型: _.isPlainObject()可以认为是选项表类型, _.isArray() && item.id可以认为是多选选项表
// 判断dump类型: _.isPlainObject()可以认为是选项表类型, 取_.toNumber(value.id)做为dump; _.Object()可以认为不需要dump

/**
 * 获取Attribute的MdFieldId
 */
export function getMdFieldId(attr: MdAttribute | MdAttributeInput): ID {
  return _.get(attr, 'field.id') || _.get(attr, 'fieldId')
}

/**
 * 从一组attributes中, 根据唯一的mdFieldId找到attribute.
 */
export function findAttribute<T extends MdAttribute | MdAttributeInput>(attributes: Maybe<T[]>, mdFieldId: number | string): Maybe<T> {
  if (!attributes) return undefined
  return _.find(attributes, (attr: T) => {
    // eslint-disable-next-line eqeqeq
    return getMdFieldId(attr) == mdFieldId ||
      _.get(attr, 'field.name') === mdFieldId
  })
}

export function findMdFieldTuple(tuples: Maybe<MdFieldTuple[]>, mdFieldId: number | string): Maybe<MdAttribute | MdAttributeInput> {
  if (!tuples) return undefined
  return _.find(tuples, tuple => {
    const attr = tuple.attribute
    // eslint-disable-next-line eqeqeq
    return getMdFieldId(attr) == mdFieldId || _.get(attr, 'field.name') === mdFieldId
  })?.attribute
}

/**
 * 判断一个attribute是否是空的(未设置值)
 */
export function isEmptyAttr(attribute: MdAttribute | MdAttributeInput | undefined):boolean {
  if (!attribute) return true
  return _.isNil(attribute.value) || attribute.value === '' ||
    (_.isArray(attribute.value) && _.isEmpty(attribute.value)) ||
    (_.isPlainObject(attribute.value) && !attribute.value.id)
}

/**
 * 排除空的attributes, 提交前调用
 */
export function filterEmptyAttr(attributes: (MdAttribute | MdAttributeInput)[]): (MdAttribute | MdAttributeInput)[] {
  return attributes.filter(attr => !isEmptyAttr(attr))
}

/**
 * 解析出一个attribute可直接用于比较的值, 可用于equals/sort等
 */
export function resolveAttrComparableValue(attr?: MdAttribute | MdAttributeInput) {
  if (!attr) return undefined
  // 选项表类型, 取value的id直接返回
  if (_.isPlainObject(attr.value)) return _.toNumber(attr.value.id)
  // 多选选项表, .isArray() && item.id可以认为是多选选项表
  else if (_.isArray(attr.value) && attr.value.length > 0 && _.isPlainObject(attr.value[0])) {
    return attr.value.map(item => item.id)
  // 其它类型直接比较值
  } else {
    return attr.value
  }
}

/**
 * 解析出来一个attribute的特征值, 可用于拼dump
 */
export function resolveAttrInputDumpValue(attr?: MdAttribute | MdAttributeInput) {
  if (!attr) return undefined
  // 选项表类型, 取value的id直接返回
  if (_.isPlainObject(attr.value)) return _.toNumber(attr.value.id)
  // 附件/多选选项表isObject, 但这里不把值取出来
  else return _.isObject(attr.value) ? undefined : attr.value
}

/**
 * 比较两个attribute的<b>值</b>是否一致, 只是值而已, 不限制必须是同一个mdField
 */
export function isAttrEquals(attr1?: MdAttribute | MdAttributeInput,
                             attr2?: MdAttribute | MdAttributeInput): boolean {
  const inputValue1 = resolveAttrComparableValue(attr1)
  const inputValue2 = resolveAttrComparableValue(attr2)
  return _.isEqual(inputValue1, inputValue2)
}

/**
 * 比较两个attribute的值是否一致. 如果两个值都为空的话(未设置), 则认为不一致, 可用于检测变更
 */
export function isAttrEqualsExceptNil(attr1?: MdAttributeInput,
                                      attr2?: MdAttributeInput): boolean {
  const inputValue1 = resolveAttrComparableValue(attr1)
  const inputValue2 = resolveAttrComparableValue(attr2)
  return util.objects.equalExceptNil(inputValue1, inputValue2)
}

/**
 * 将一个MdAttribute转换成MdAttributeInput
 */
export function convertAttrValueToInput(attribute: MdAttribute): MdAttributeInput {
  if ('fieldId' in attribute) return attribute as MdAttributeInput

  const input: MdAttributeInput | any = util.objects.stripNil({
    ...attribute,
    fieldId: getMdFieldId(attribute) || ''
  }, true)

  delete input.field
  delete input.hasDefaultValue
  delete input.__typename
  // strip audit fields of nested objects
  util.objects.stripField(input, 'uid', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy', 'ver')
  return input
}

/**
 * 将一个MdFieldTuple转换成MdAttribute
 */
export function convertAttrInputToValue(tuple: MdFieldTuple): MdAttribute {
  const attribute: MdAttribute | any = {
    ...tuple.attribute,
    field: tuple.mdField
  }

  if (!isEmptyAttr(tuple.attribute)) {
    if (tuple.mdField.dataType === '选项表' && _.isPlainObject(tuple.attribute.value)) {
      attribute.value = _.toNumber(tuple.attribute.value.id)
      attribute.extra = {
        dataKey: tuple.attribute.value.key,
        dataValue: tuple.attribute.value.value,
        valueForShort: tuple.attribute.value.valueForShor
      }
    } else if (tuple.mdField.dataType === '多选选项表' &&
      tuple.attribute.value &&
      tuple.attribute.value.length > 0 &&
      _.isPlainObject(tuple.attribute.value[0])) {
      attribute.value = tuple.attribute.value.map((dictEntry: any) => _.toNumber(dictEntry.id))
      attribute.extra = {}
      tuple.attribute.value.forEach((dictEntry: any) => {
        attribute.extra[_.toNumber(dictEntry.id)] = {
          dataKey: dictEntry.key,
          dataValue: dictEntry.value,
          valueForShort: dictEntry.valueForShor
        }
      })
    }
  } else {
    delete attribute.value
  }

  delete attribute.fieldId
  return attribute
}

/**
 * 因为MdAttributeInput没有MdField, 可以用此方法模拟一个包含部分关键信息的假MdField.<br/>
 * 只能适用于有限的场景, 缺失或不准确的信息包括: <br/>
 * <ul>
 *   <li>字段名: 取的是attr里面的alias, 如果没有alias最取fieldId, 只能保证不为空</li>
 *   <li>字段类型: "选项表"/"多选选项表"应该是准的, 原子类型value返回"文本", 对象类型的返回"组合"</li>
 *   <li>字段设置: 想太多了</li>
 * </ul>
 */
export function simulateMdField(input: MdAttributeInput): MdField {
  if (isEmptyAttr(input)) {
    return {
      id: input.fieldId,
      name: input.alias || input.fieldId,
      dataType: '文字'
    }
  }

  if (_.isObject(input.value)) {
    return {
      id: input.fieldId,
      name: input.alias || input.fieldId,
      dataType: '选项表'
    }
  }

  if (_.isArray(input.value) && input.value.length > 0 && _.isPlainObject(input.value[0])) {
    return {
      id: input.fieldId,
      name: input.alias || input.fieldId,
      dataType: '多选选项表'
    }
  }

  if (_.isArray(input.value) && input.value.length > 0 && _.isString(input.value[0])) {
    return {
      id: input.fieldId,
      name: input.alias || input.fieldId,
      dataType: '附件'
    }
  }

  return {
    id: input.fieldId,
    name: input.alias || input.fieldId,
    dataType: _.isObject(input.value) ? '组合' : '文字'
  }
}

/**
 * 将一组MdAttribute转换成FilterCondition
 */
export function convertAttrValueToFilterCondition(attributes: MdAttribute[],
                                                  attrFieldPrefix = '@'): FilterConditionView {
  return {
    filterType: FilterType.And,
    conditions: attributes.filter(attr => !isEmptyAttr(attr)).map(attr => {
      const fieldType = attr.field?.dataType || _.get(attr, 'fieldType')
      if (fieldType === '选项表') {
        return {
          filterType: FilterType.Number,
          filterViewDef: mdAttrAgGridFilterViewDef(attr.field, attr, attrFieldPrefix),
          // 有些attr没经过DataFetcher, 直接从缓存取出来, 没办法按GraphQL的schema取
          field: attrFieldPrefix + (attr.field?.id || _.get(attr, 'fieldId')),
          operator: 'in',
          value: attr.value + ''
        }
      } else if (fieldType === '多选选项表') {
        return <FilterCondition>{
          filterType: FilterType.Array,
          filterViewDef: mdAttrAgGridFilterViewDef(attr.field, attr, attrFieldPrefix),
          field: attrFieldPrefix + (attr.field?.id || _.get(attr, 'fieldId')),
          operator: 'in',
          value: attr.value + ''
        }
      } else {
        let filterType = FilterType.String
        if (fieldType === '数字') {
          filterType = FilterType.Number
        } else if (fieldType === '是否') {
          filterType = FilterType.Bool
        } else if (fieldType === '日期') {
          filterType = FilterType.Date
        }
        return <FilterCondition>{
          filterType: filterType,
          filterViewDef: mdAttrAgGridFilterViewDef(attr.field, attr, attrFieldPrefix),
          field: attrFieldPrefix + (attr.field?.id || _.get(attr, 'fieldId')),
          operator: 'equals',
          value: attr.value + ''
        }
      }
    })
  }
}

/**
 * 将一组MdFieldTuple转换成FilterCondition
 */
export function convertAttrTupleToFilterCondition(attrTuples: MdFieldTuple[],
                                                  attrFieldPrefix = '@'): FilterCondition {
  return {
    filterType: FilterType.And,
    conditions: attrTuples.filter(tuple => !isEmptyAttr(tuple.attribute)).map(tuple => {
      if (tuple.mdField.dataType === '选项表') {
        return <FilterCondition>{
          filterType: FilterType.Number,
          field: attrFieldPrefix + tuple.mdField.id,
          operator: 'in',
          value: resolveAttrInputDumpValue(tuple.attribute) + ''
        }
      } else if (tuple.mdField.dataType === '多选选项表') {
        return <FilterCondition>{
          filterType: FilterType.Array,
          field: attrFieldPrefix + tuple.mdField.id,
          operator: 'in',
          value: _.isArray(tuple.attribute.value) ? tuple.attribute.value.map(v => v.id).join(',') : tuple.attribute.value
        }
      } else {
        let filterType = FilterType.String
        if (tuple.mdField.dataType === '数字') {
          filterType = FilterType.Number
        } else if (tuple.mdField.dataType === '是否') {
          filterType = FilterType.Bool
        } else if (tuple.mdField.dataType === '日期') {
          filterType = FilterType.Date
        }
        return <FilterCondition>{
          filterType: filterType,
          field: attrFieldPrefix + tuple.mdField.id,
          operator: 'equals',
          value: tuple.attribute.value + ''
        }
      }
    })
  }
}

/**
 * 生成跟后台dump格式一样的attribute dump
 */
export function attrValueDump(attribute: MdAttribute): string {
  if (attribute.tbd || isEmptyAttr(attribute)) return ''
  return attribute.field.id + '➧' + attribute.value + '»'
}

export function attrTupleDump(attrTuple: MdFieldTuple): string {
  const attr = convertAttrInputToValue(attrTuple)
  return attrValueDump(attr)
}

export function attrInputDump(attrInput: MdAttributeInput): string {
  if (attrInput.tbd || isEmptyAttr(attrInput)) return ''
  return attrInput.fieldId + '➧' + resolveAttrInputDumpValue(attrInput) + '»'
}

/**
 * 比较两组attributes特征是否是否一致, 可用于检查主数据是否换号
 */
export function compareFeatureAttributes(attributes: MdAttribute[], attrInputs: MdFieldTuple[]): boolean {
  const attrDump = attributes.filter(attr => attr.feature && !isEmptyAttr(attr)).map(attr => attrValueDump(attr)).filter(dump => dump)
  const inputDump = attrInputs.filter(tuple => {
    // 之前的属性是tbd, 则不参与比较
    const previousAttr = findAttribute(attributes, tuple.mdField.id)
    return tuple.attribute.feature && (!previousAttr || !previousAttr.tbd)
  }).map(input => attrTupleDump(input)).filter(dump => dump)
  return _.isEqual(attrDump.sort(), inputDump.sort())
}

/**
 * 将attribute格式化为string
 */
export function formatAttribute(attr: MdAttribute | MdFieldTuple | null | undefined, includeFieldName = true): string {
  if (!attr) return ''
  let value = ''

  if ('field' in attr) {
    if (isEmptyAttr(attr)) return ''

    if (attr.field.dataType === '选项表' || attr.field.dataType === '扩展表') {
      value = `${attr.extra?.dataValue}(${attr.extra?.dataKey})`
    } else if (attr.field.dataType === '多选选项表') {
      value = attr.value.map((dictEntryId: string) => `${attr.extra[dictEntryId].dataValue}(${attr.extra[dictEntryId].dataKey})`).join(',')
    } else if (attr.field.dataType === '是否') {
      value = attr.value ? (attr.field.option.yesLabel || '是') : (attr.field.option.noLable || '否')
    } else if (attr.field.dataType !== '附件' && attr.field.dataType !== '组合') {
      value = attr.value
    }
    return (includeFieldName ? (attr.field.payload?.alias || attr.field.name) : '') + ':' + value
  }

  if ('mdField' in attr) {
    if (isEmptyAttr(attr.attribute)) return ''

    if (attr.mdField.dataType === '选项表' || attr.mdField.dataType === '扩展表') {
      value = `${attr.attribute.value.value}(${attr.attribute.value.key})`
    } else if (attr.mdField.dataType === '多选选项表') {
      value = attr.attribute.value.map((dictEntry: MdDictionaryEntry) => `${dictEntry.value}(${dictEntry.key})`).join(',')
    } else if (attr.mdField.dataType === '是否') {
      value = attr.attribute.value ? (attr.mdField.option.yesLabel || '是') : (attr.mdField.option.noLable || '否')
    } else if (attr.mdField.dataType !== '附件' && attr.mdField.dataType !== '组合') {
      value = attr.attribute.value
    }
    return (includeFieldName ? (attr.mdField.payload?.alias || attr.mdField.name) : '') + ':' + value
  }

  return ''
}

/**
 * 数据MdField数据类型返回对应的FloatingFilter
 */
export function mdAttrAgGridFloatingFilter(mdField: MdField): string | undefined {
  switch (mdField.dataType) {
    case '文字':
    case '数字':
      return 'textFloatingFilter'
    case '是否':
      return 'boolFloatingFilter'
    case '扩展表':
    case '选项表':
    case '多选选项表':
      return 'mdDictAttrFloatingFilter'
    default: return undefined
  }
}

/**
 * 数据MdField数据类型返回对应的FilterViewDef
 */
export function mdAttrAgGridFilterViewDef(mdField: MdField, attr: MdAttribute = null,
                                          attrFieldPrefix = '@'): FilterViewDef {
  const viewDef:FilterViewDef = {
    fieldKey: attrFieldPrefix + mdField.id,
    fieldName: mdField.payload?.alias || mdField.name || ''
  }
  switch (mdField.dataType) {
    case '文字':
      viewDef.filterViewType = FilterViewType.StringFilter; break
    case '数字':
      viewDef.filterViewType = FilterViewType.NumberFilter; break
    case '是否':
      viewDef.filterViewType = FilterViewType.BooleanFilter
      if (!viewDef.customProps) viewDef.customProps = {}
      if (mdField.option.yesLabel) viewDef.customProps.yesLabel = mdField.option.yesLabel
      if (mdField.option.noLabel) viewDef.customProps.noLabel = mdField.option.noLabel
      break
    case '日期':
      viewDef.filterViewType = FilterViewType.DateFilter; break
    case '扩展表':
      viewDef.filterViewType = FilterViewType.CustomFilter
      viewDef.customFilter = 'cmp-md-extend-data-filter'
      viewDef.customProps = {
        modelName: mdField.option?.modelName || attr?.extra?.modelName
      }
      break
    case '选项表':
    case '多选选项表':
      viewDef.filterViewType = FilterViewType.CustomFilter
      viewDef.customFilter = 'cmp-md-dict-attr-filter'
      viewDef.customProps = {
        dictId: mdField.option?.dictId || attr?.extra?.dictId,
        multipleDict: mdField.dataType === '多选选项表'
      }
      break
  }
  return viewDef
}

/**
 * 将一个MdAttribute转换成MdExtendData
 */
export function convertAttrToMdExtendData(attribute: MdAttribute): Maybe<MdExtendData> {
  if (!attribute.value) return null
  return {
    dataId: attribute.value,
    dataKey: attribute.extra.dataKey,
    dataValue: attribute.extra.dataValue,
    extra: attribute.extra
  }
}

export function fetchMdFields(vue: Vue, mdFieldIds: ID[]) {
  if (_.isEmpty(mdFieldIds)) return Promise.resolve([])

  return vue.$apollo.query({
    query: gql`query MdFields($rowRequest: RowRequest!) {
        MdFields(rowRequest: $rowRequest) {
            rows { id name dataType option builtin }
        }
    }`,
    variables: {
      rowRequest: {
        filter: {
          filterType: FilterType.Number,
          field: 'id',
          operator: 'in',
          value: mdFieldIds.join(',')
        }
      }
    }
  }).then(data => {
    return data.data.MdFields.rows.sort((lhs: MdField, rhs: MdField) => {
      return _.indexOf(mdFieldIds, _.toString(lhs.id)) -
        _.indexOf(mdFieldIds, _.toString(rhs.id))
    })
  })
}

export function fetchMdFieldsByName(vue: Vue, mdFieldNames: string[]) {
  return vue.$apollo.query({
    query: gql`query MdFields($rowRequest: RowRequest!) {
        MdFields(rowRequest: $rowRequest) {
            rows { id name dataType option builtin }
        }
    }`,
    variables: {
      rowRequest: {
        filter: {
          filterType: FilterType.String,
          field: 'name',
          operator: 'in',
          value: mdFieldNames.join(',')
        }
      }
    }
  }).then(data => {
    return data.data.MdFields.rows.sort((lhs: MdField, rhs: MdField) => {
      return _.indexOf(mdFieldNames, lhs.name) - _.indexOf(mdFieldNames, rhs.name)
    })
  })
}

export function initMdAttributeIfNeeded(attr: MdAttribute | MdAttributeInput, mdField: MdField) {
  return attr || {
    fieldId: mdField.id,
    value: !_.isUndefined(mdField.option?.defaultValue) ? mdField.option?.defaultValue : null
  }
}

export function getMdDataFilterFieldOptions() {
  const fixedFields = LocalDbDao.getMdDomainFixedFields('', true)
  const fieldOptions: FilterViewDef[] = LocalDbDao.getExplorerType('MdData').getFilterOptions()
  fieldOptions.forEach(fieldOption => {
    const fixedField = _.find(fixedFields, fixedField => fixedField.field === fieldOption.fieldName)
    if (!fixedField || fixedField.disable) return
    fieldOption.fieldName = fixedField.name
  })
  return fieldOptions
}

export function resolveAttrFieldIds(filterCondition: FilterCondition, attrFieldPrefix = '@') {
  if (!filterCondition) return []
  const idx = filterCondition.field ? filterCondition.field.indexOf('@') : -1
  const fieldIds = []
  if (filterCondition.field?.startsWith(attrFieldPrefix)) {
    fieldIds.push(filterCondition.field.replace(attrFieldPrefix, ''))
  }
  if (!_.isEmpty(filterCondition.conditions)) {
    filterCondition.conditions.forEach(subCondition => {
      fieldIds.push(...resolveAttrFieldIds(subCondition, attrFieldPrefix))
    })
  }
  return _.uniq(fieldIds)
}

/******************************************************************************/
/* MdData */
/******************************************************************************/

/**
 * 弹出主数据详情Dialog
 */
export function showMdDataViewer(vue: Vue, mdData: MdData | string | number) {
  const props: Indexed = {}
  if (_.isNumber(mdData)) {
    props.mdDataId = mdData
  } else if (_.isString(mdData)) {
    props.mdDataCode = mdData
  } else {
    props.mdData = mdData
  }

  const editor = new IcmpMdDataDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: props,
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

/**
 * 弹出主数据编辑Dialog
 */
export function showMdDataEditor(vue: Vue, props: object,
                                 submitCallback: (mdData: MdData) => any) {
  const editor = new IcmpMdDataEditorDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: props,
    mounted() {
      editor.$on('submit', submitCallback)
    },
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

/**
 * 弹出扩展数据详情Dialog
 */
export function showMdExtendDataDetail(vue: Vue,
                                       modelName: string,
                                       mdExtendData: MdExtendData) {
  const editor = new IcmpMdExtendDetailPanel({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: {
      modelName, mdExtendData
    },
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

/******************************************************************************/
/* MdTable */
/******************************************************************************/

export function updateMdTableImportContext(context: MdTableImportContext, newContext: MdTableImportContext) {
  if (context === newContext) return context
  util.objects.refill(newContext.mappings || [], context.mappings)
  util.objects.refill(newContext.importColumns || [], context.importColumns)
  context.importMode = newContext.importMode
  context.rawData = newContext.rawData
  context.deltaData = newContext.deltaData
  context.currentStep = newContext.currentStep
  context.table = newContext.table
  context.options = newContext.options
  context.table.importFromSql = newContext.table.importFromSql
  context.table.externalDataSourceId = newContext.table.externalDataSourceId
  context.rawDataCount = newContext.rawDataCount;
  (context as any).isNew = false
  return context
}

export function showMdTableDataViewer(vue: Vue, columns: MdTableColumn[], tableData: MdTableData) {
  const editor = new IcmpMdTableDataViewer({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: {
      columns, tableData
    },
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

const MdTablePermissions = [
  'management',
  'access_private_table',
  'edit_table_data',
  'edit_readonly_table_data'
]

function isMdTableOwner(mdTable: MdTable): boolean {
  // eslint-disable-next-line eqeqeq
  return !!_.find(mdTable.ownerIds, id => id == LocalDbDao.getPrincipal().identity)
}

function isMdTableCooperator(mdTable: MdTable): boolean {
  // eslint-disable-next-line eqeqeq
  return !!_.find(mdTable.cooperatorIds, id => id == LocalDbDao.getPrincipal().identity)
}

function isMdTableReader(mdTable: MdTable): boolean {
  // eslint-disable-next-line eqeqeq
  return !!_.find(mdTable.readerIds, id => id == LocalDbDao.getPrincipal().identity)
}

export function checkMdTablePermission(mdTable: MdTable, permission: string) {
  if (!mdTable) return false
  if (LocalDbDao.getPrincipal().adminLevel === 1) return true
  switch (permission) {
    case 'import':
    case 'management': return isMdTableOwner(mdTable)
    case 'access_private_table':
      return isMdTableOwner(mdTable) || isMdTableCooperator(mdTable) || isMdTableReader(mdTable)
    case 'edit_table_data': return isMdTableOwner(mdTable) || isMdTableCooperator(mdTable)
    case 'edit_readonly_table_data': return isMdTableOwner(mdTable)
    default: return false
  }
}

export function newMdTableData(columns: MdTableColumn[]) {
  const tableData = {
    values: {}
  } as MdTableData
  columns.forEach(column => {
    if (!_.isNil(column.option.defaultValue)) {
      tableData.values[column.colName] = column.option.defaultValue
    }
  })
  return tableData
}

export function removeFavorite(tableCode: string, vue: Vue) {
  vue.$confirm('确认取消关注?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    let favoriteTables = LocalDbDao.getUserSetting(APP_REALM_CODE, 'user_favorite_md_tables')?.value || []
    _.remove(favoriteTables, exist => exist === tableCode)
    LocalDbDao.saveUserSetting({ realm: APP_REALM_CODE, code: 'user_favorite_md_tables', value: favoriteTables, id: 0 }).then(setting => {
      vue.$message({
        message: '取消成功', type: 'success', showClose: true
      })
      LocalDbDao.updateLocalUserSetting([setting])
      vue.$emitToGlobal('favorite-md-table-changed')
    })
  })
}

export function removeShortcuts(tableCode: string, vue: Vue) {
  vue.$confirm('确认移除快捷方式?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    let mdTableShortcuts = LocalDbDao.getSysSetting(APP_REALM_CODE, 'md_table_shortcuts')
    _.remove(mdTableShortcuts.value, exist => exist === tableCode)
    LocalDbDao.saveSysSetting(mdTableShortcuts).then(setting => {
      vue.$message({
        message: '移除成功', type: 'success', showClose: true
      })
      LocalDbDao.updateLocalSysSettings([setting])
      vue.$emitToGlobal('md-table-shortcuts-changed')
    })
  })
}
