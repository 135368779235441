import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry } from '@/module/components/lolth-explorer/supports'
import CmpMetIntroducersCellRenderer
  from '@/module/plugin-meeting/views/explorer-support/cmp-met-introducers-cell-renderer.vue'
import store from '@/store'

export const REALM_CODE_ATD = 'Attendance'

export class AtdModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    // 注册ag-grid frameworkComponents
    // FrameworkComponentsRegistry.metIntroducersRenderer = CmpMetIntroducersCellRenderer
    // store.commit('master-data/md-store/registerMdExtendDataDetailPanels', [{
    //   modelName: 'MetCustomer',
    //   customComponentName: 'cmp-met-customer-form-body'
    // }], { root: true })
  }
}

export default new AtdModuleHook()
