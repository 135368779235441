

























import { Component, Ref } from 'vue-property-decorator'
import BaseInput from './base-input'

@Component
export default class CmpMdTableDataInputText extends BaseInput<string> {
  @Ref() readonly vInput: any

  get inputType() {
    return this.columnOption.multipleLine ? 'textarea' : 'text'
  }

  get optionItems() {
    return this.columnOption?.optionItemsFromScript || this.columnOption?.optionItems || []
  }

  get hasOptionItems() {
    return this.optionItems.length > 0
  }

  querySearch(queryString: string, cb: Function) {
    const result: string[] = queryString
      ? this.optionItems.filter(this.createFilter(queryString))
      : this.optionItems
    cb(result.map(item => {
      return {
        value: item
      }
    }))
  }

  createFilter(queryString: string) {
    return (optionItem: string) => {
      return (optionItem.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  focus() {
    this.vInput?.focus()
  }
}
