import util from '@/d2admin/libs/util'
import _ from 'lodash'
import { ApolloError, ApolloQueryResult } from 'apollo-client'
import { GraphQLError } from 'graphql'
import { ErrorInfo, FilterType, IdBasedModel, RowRequest } from '@/module/graphql'
import {
  BatchMutation,
  BatchMutationResult,
  ErrorHandingBroker,
  GroupedErrors,
  MutationBroker,
  PreviewBroker
} from '@/module/common'
import { Maybe } from 'graphql/jsutils/Maybe'
import { FetchResult } from 'apollo-link'
import LocalDbDao from '@/module/common/local-db-dao'
import Vue from 'vue'
import { showErrorsMessageBox } from '@/module/common/util/view-util'
import { HandledError } from '@/d2admin/plugin/util'
import json5 from 'json5'
import { OperationVariables } from 'apollo-client/core/types'
import { DATE_TIME_FORMAT } from '@/module/common/constants'
import moment from 'moment'
import axios from '@/d2admin/plugin/axios'

export function buildGraphQLQueryPartInput(variable: any,
                                           wrapper = false,
                                           stripNil = false): string {
  let cloned = _.clone(variable)
  if (stripNil) util.objects.stripNil(cloned)
  // strip audit field from model object
  util.objects.stripField(cloned, '__typename', 'uid', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy')
  let text:string = json5.stringify(cloned, { quote: '"' })
  const beginWrapper = !wrapper ? '' : _.isArray(variable) ? '[' : '{'
  const endWrapper = !wrapper ? '' : _.isArray(variable) ? ']' : '}'

  return beginWrapper +
    text.substring(1, text.length - 1) // remove quotes
      .replace(/"?(filterType)"?:"(.*?)"/g, '$1:$2') // convert enum FilterType
      .replace(/"?(sort)"?:"(ASC|DESC)"/g, '$1:$2') + // convert enum Sort
    endWrapper
}

export function buildGraphQLInput<T>(value: T, ...stripFields: string[]) {
  let cloned = _.clone(value)
  util.objects.stripNil(cloned)
  util.objects.stripField(cloned, '__typename', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy')
  if (_.isEmpty(stripFields)) stripFields = ['uid']
  util.objects.stripField(cloned, ...stripFields)
  formatDate(cloned)
  return cloned
}

function formatDate(obj: any) {
  if (_.isArray(obj)) {
    obj.forEach(el => formatDate(el))
  } else if (_.isObject(obj)) {
    Object.keys(obj).forEach(key => {
      const value = _.get(obj, key)
      if (_.isDate(value)) {
        _.set(obj, key, moment(value).format(DATE_TIME_FORMAT))
      } else if (_.isObject(value)) {
        formatDate(value)
      }
    })
  }
  return obj
}

export function buildGraphQLJsonInput(value: any) {
  return json5.stringify(buildGraphQLInput(value), { quote: '"' })
}

export function resolveStringArray(value: string): string[] {
  // eslint-disable-next-line no-useless-escape
  return value.split(/[,\s\[\]]/).filter(part => part.length > 0)
}

export function resolveNumber(value: any): number {
  if (value === '') return null
  return _.toNumber(value)
}

export function extractGraphQLError(error: Error): ErrorInfo[] {
  if (error instanceof ApolloError || error instanceof GraphQLError || 'config' in error) {
    if ('graphQLErrors' in error && error.graphQLErrors && error.graphQLErrors.length > 0) {
      const gqlError = error.graphQLErrors[0]
      if ('extensions' in gqlError && gqlError.extensions) {
        if (gqlError.extensions.errors && gqlError.extensions.errors.length > 0) {
          return gqlError.extensions.errors
        }
        if (gqlError.extensions.error) {
          return [gqlError.extensions.error]
        }
      }
    }
  }
  return []
}

/**
 * 用于提交数据到后台生成预览
 */
export function submitPreview(vue: Vue, broker: PreviewBroker): Promise<Maybe<ApolloQueryResult<any>>> {
  if (broker.ignoreWarning) {
    _.set(broker.previewOptions, 'context.headers.ctx-ignore-warning', broker.ignoreWarning)
  }
  if (broker.fullValidate) {
    _.set(broker.previewOptions, 'context.headers.ctx-full-validate-on-save', broker.fullValidate)
  }
  return vue.$apollo.query(broker.previewOptions).then(data => {
    const errors = broker.collectErrors?.(data) || []
    return processErrors(vue, data, errors, broker, () => {
      return data
    })
  }).catch(err => {
    const errors = extractGraphQLError(err)
    if (!_.isEmpty(errors)) {
      return processErrors(vue, null, errors, broker, () => {
        broker.ignoreWarning = true
        return submitPreview(vue, broker)
      })
    } else {
      throw err
    }
  }) as Promise<Maybe<ApolloQueryResult<any>>>
}

/**
 * 用于提交数据, 封装了ignoreWarning二次确认
 */
export function submitMutation(vue: Vue, broker: MutationBroker): Promise<Maybe<FetchResult<any>>> {
  if (broker.ignoreWarning) {
    _.set(broker.mutationOptions, 'context.headers.ctx-ignore-warning', broker.ignoreWarning)
  }
  if (broker.fullValidate) {
    _.set(broker.mutationOptions, 'context.headers.ctx-full-validate-on-save', broker.fullValidate)
  }
  return vue.$apollo.mutate(broker.mutationOptions).then(data => {
    const errors = broker.collectErrors?.(data) || []
    return processErrors(vue, data, errors, broker, () => {
      broker.ignoreWarning = true
      return submitMutation(vue, broker)
    })
  }).catch(err => {
    const errors = extractGraphQLError(err)
    if (!_.isEmpty(errors)) {
      return processErrors(vue, null, errors, broker, () => {
        broker.ignoreWarning = true
        return submitMutation(vue, broker)
      })
    } else {
      throw err
    }
  })
}

function processErrors(vue: Vue,
                       data: FetchResult<any> | ApolloQueryResult<any> | null,
                       errors: GroupedErrors,
                       broker: ErrorHandingBroker,
                       warningConfirmCallback: () => any): Promise<Maybe<FetchResult<any> | ApolloQueryResult<any>>> {
  if (_.isEmpty(errors)) return Promise.resolve(data)

  // 解析worstErrorLevel
  const worstElv = LocalDbDao.worstErrorLevel(errors)
  if (!worstElv) return Promise.resolve(data)

  if (worstElv.key === 'warn') {
    if (broker.handleWarnings) {
      broker.handleWarnings(errors)
      return
    }
    const h = vue.$createElement
    return vue.$msgbox({
      title: '警告',
      message: h('div', {
        attrs: {
          class: 'max-height'
        }
      }, [
        h('p', '提交数据有错误警告, 是否继续提交?'),
        h('lolth-errors', {
          props: {
            errors: errors
          }
        })
      ]),
      showConfirmButton: true,
      confirmButtonText: '确定',
      showCancelButton: true,
      cancelButtonText: '取消'
    }).then(warningConfirmCallback)
      .catch(() => {
        throw new HandledError()
      })
  } else {
    if (broker.handleErrors) broker.handleErrors(errors)
    else showErrorsMessageBox(vue, errors)

    throw new HandledError()
  }
}

/**
 * 加工mutation.variables, 根据typeKey获取对应id的ver
 */
export async function fetchVer<R = any, TVariables = OperationVariables>(typeKey: string,
                                                                         mutations: BatchMutation<R, TVariables>) {
  const explorerType = LocalDbDao.getExplorerType(typeKey)
  const verData: IdBasedModel[] = await axios.post('/graphql', {
    query: `query ($rowRequest: RowRequest!) {
        ${explorerType.queryType}(rowRequest: $rowRequest) {
          rows { ${explorerType.getIdFieldName()} ver }
        }
      }`,
    variables: {
      rowRequest: {
        filter: {
          filterType: FilterType.InIds,
          value: mutations.mutations.map(m => _.get(m.variables, explorerType.getIdFieldName())).filter(v => v).join(',')
        }
      } as RowRequest
    }
  }).then(resp => {
    return resp.data.data[explorerType.queryType].rows
  })

  verData.forEach(v => {
    const mutation = mutations.mutations.find(m =>
      _.get(m.variables, explorerType.getIdFieldName()) === _.get(v, explorerType.getIdFieldName()))
    if (mutation) {
      _.set(mutation.variables as any, 'ver', v.ver)
    }
  })
}

/**
 * 批量提交mutation, 提交结果汇总到一个Result中. 可以指定key找到
 * @param vue
 * @param mutations
 */
export function batchSubmitMutations<R = any, TVariables = OperationVariables>(vue: Vue, mutations: BatchMutation<R, TVariables>): Promise<BatchMutationResult[]> {
  if (mutations.ignoreWarning) {
    _.values(mutations.mutations).forEach(mutation => {
      _.set(mutation, 'context.headers.ctx-ignore-warning', true)
    })
  }
  if (mutations.fullValidate) {
    _.values(mutations.mutations).forEach(mutation => {
      _.set(mutation, 'context.headers.ctx-full-validate-on-save', true)
    })
  }

  return Promise.all(mutations.mutations.map(mutation => {
    return vue.$apollo.mutate(mutation).then(response => {
      return {
        key: mutation.key,
        response: response,
        errors: null
      }
    }).catch(err => {
      const errors = extractGraphQLError(err)
      if (!_.isEmpty(errors)) {
        return new Promise<BatchMutationResult>((resolve, reject) => {
          processErrors(vue, null, errors, {
            handleErrors() {
              resolve({
                key: mutation.key,
                response: null,
                errors: errors
              })
            }
          }, () => {
            return submitMutation(vue, {
              mutationOptions: mutation,
              ignoreWarning: true,
              fullValidate: mutations.fullValidate
            }).then(resp => {
              resolve({
                key: mutation.key,
                response: resp,
                errors: null
              })
            })
          })
        })
      } else {
        throw err
      }
    })
  }))
}
