import moment from 'moment'
import { DatePickerOptions, ElDatePicker } from 'element-ui/types/date-picker'
import Vue from 'vue'
import { GroupedErrors } from '@/module/common'
import _ from 'lodash'
import {
  ErrorInfo,
  FilterCondition,
  FilterType,
  MutationExecuteMdValidationArgs,
  SimpleMdDiagnosisFragmentDoc
} from '@/module/graphql'
import LolthDiagnosisDialog from '@/module/components/validation/icmp-diagnosis-dialog.vue'

import { stripInvalidFilterCondition } from '@/module/components/lolth-explorer/supports'
import util from '@/d2admin/libs/util'
import gql from 'graphql-tag'
import { Maybe } from 'graphql/jsutils/Maybe'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { ColDef, GridApi, GridOptionsWrapper, RowNode } from 'ag-grid-community'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import Sortable, { SortableEvent } from 'sortablejs'
import { Indexed } from '@/d2admin/delegate'
import LocalDbDao from '@/module/common/local-db-dao'
import IcmpProgress from '@/module/butler/views/components/icmp-progress.vue'
import { Message, Notification as ENotification } from 'element-ui'
import { MessageBoxData } from 'element-ui/types/message-box'

export function buildDatePickerOption(futureOnly: boolean = false) {
  const option: DatePickerOptions = {
    shortcuts: [{
      text: '三个月前',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(-3, 'months').toDate())
      }
    }, {
      text: '一个月前',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(-1, 'months').toDate())
      }
    }, {
      text: '一周前',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(-7, 'days').toDate())
      }
    }, {
      text: '三天前',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(-3, 'days').toDate())
      }
    }, {
      text: '今天',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', new Date())
      }
    }, {
      text: '三天后',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(3, 'days').toDate())
      }
    }, {
      text: '一周后',
      onClick(picker: ElDatePicker) {
        picker.$emit('pick', moment().add(7, 'days').toDate())
      }
    }]
  }
  if (futureOnly) option.disabledDate = (date: Date) => moment(date).isBefore(new Date(), 'day')
  return option
}

export function setupSortable(element: string | HTMLElement,
                              onDragStop: (newIndex: number, oldIndex: number, data: any) => void,
                              dragEntireRow: boolean = false,
                              data: any = null) {
  if (typeof element === 'string') {
    element = document.querySelector(element) as HTMLElement
  }
  if (element) {
    const options: Indexed = {
      filter: '.sort-disabled',
      animation: 150,
      onEnd(event: SortableEvent) {
        onDragStop(event.newIndex, event.oldIndex, data)
      }
    }
    if (!dragEntireRow) {
      options.handle = '.sort-handle'
    }
    return Sortable.create(element, options)
  }
}

export function showErrorsMessageBox(vue: Vue, errors: GroupedErrors,
                                     message: string = null,
                                     title: string = '错误'): Promise<MessageBoxData> {
  if (_.isEmpty(errors) && !message) return
  let transformedErrors: ErrorInfo[]

  if (_.isArray(errors)) {
    transformedErrors = errors
  } else {
    _.keys(errors).forEach(key => {
      errors[key]?.forEach(errorInfo => {
        errorInfo.msg = key + ':' + (errorInfo.msg || '')
      })
    })
    transformedErrors = _.flatten(_.values(errors))
  }

  const h = vue.$createElement
  const children = []
  if (message) {
    children.push(h('span', {}, message))
  }
  children.push(h('lolth-errors', {
    attrs: {
      class: 'max-height'
    },
    props: {
      errors: transformedErrors
    }
  }))
  return vue.$msgbox({
    title: title,
    message: h('div', children),
    confirmButtonText: '确定',
    cancelButtonText: '取消'
  })
}

/**
 * 重新加载App
 * @param message
 * @param clearLocalStorage
 * @param delay 延时3秒, 以保存其它异步写操作执行完
 */
export function reloadApp(clearLocalStorage = false,
                          delay = 3000) {
  if (clearLocalStorage) {
    localStorage.clear()
    document.cookie = ''
  }
  setTimeout(() => {
    ENotification({
      title: '通知',
      dangerouslyUseHTMLString: true,
      message: '应用程序已更新, 点击立即更新',
      type: 'warning',
      duration: 0,
      onClick() {
        window.location.reload()
      }
    })
  }, delay)
}

export function sendNotification(message: string) {
  try {
    if (Notification.permission === 'granted') {
      const n = new Notification('系统通知', { icon: './icon.ico', body: message })
    } else {
      Message.info({
        message,
        showClose: true
      })
    }
  } catch (ignored) {}
}

/** 显示批处理任务进度 */
export function showBatchDataProcessorProgress(vue: Vue) {
  const editor = new IcmpProgress({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

//* **************************************************************************
// Diagnosis
//* **************************************************************************

export function showDiagnosisDialog(vue: Vue,
                                    targetModel: string, dataId: any,
                                    permissionCode?: string) {
  const dialog = new LolthDiagnosisDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: {
      targetModel, dataId, permissionCode
    },
    parent: vue
  })
  dialog.$mount()
  document.body.appendChild(dialog.$el)
}

export function buildValidationFilterCondition(selections: any[],
                                               explorer: LolthExplorer) {
  if (selections && selections.length) {
    return {
      filterType: FilterType.InIds,
      value: selections.map(row => row.id).join(',')
    }
  } else {
    const filterCondition = explorer.mergedFilterCondition
    stripInvalidFilterCondition(filterCondition)
    return filterCondition
  }
}

export function executeValidation(vue: Vue,
                                  targetModel: string,
                                  filterCondition: FilterCondition,
                                  loading: Maybe<boolean>,
                                  onStart: () => void = undefined,
                                  onSuccess: (result: any) => void = undefined) {
  const variables: MutationExecuteMdValidationArgs = {
    targetModel: targetModel,
    filterCondition: filterCondition
  }
  util.objects.stripField(variables.filterCondition, 'filterViewDef', 'isValid')

  if (onStart) onStart()
  if (!_.isNil(loading)) loading = true
  vue.$apollo.mutate({
    mutation: gql`mutation executeMdValidation($targetModel: String!, $filterCondition: FilterCondition!) {
        executeMdValidation(targetModel: $targetModel, filterCondition: $filterCondition) {
            ...simpleMdDiagnosis
        }
    }
    ${SimpleMdDiagnosisFragmentDoc}`,
    variables: variables
  }).then((data) => {
    const result = data.data.executeMdValidation
    if (!_.isNil(loading)) loading = false
    if (onSuccess) {
      onSuccess(result)
    } else {
      const counts = _.countBy(result, 'diagState')
      vue.$alert(`执行完成. 通过${counts.ok || 0}条, 警告${counts.warning || 0}条, 错误${counts.error || 0}条, 严重错误${counts.fatal || 0}条, 崩溃${counts.crash || 0}条`, '提示', {
        confirmButtonText: '确定',
        type: 'info'
      })
    }
    vue.$emitToGlobal('refresh-explorer')
  })
}

//* **************************************************************************
// AgGrid Utils
//* **************************************************************************

export function findAgGridColumnDef(gridOptions: GridOptions, fieldName: string): Maybe<ColDef> {
  return _.find(gridOptions.columnDefs, (colDef: ColDef) => {
    return colDef.field === fieldName
  }) as ColDef
}

export function removeAgGridColumnDef(gridOptions: GridOptions, fieldName: string) {
  _.remove(gridOptions.columnDefs, (colDef: ColDef) => {
    return colDef.field === fieldName
  })
}

export function findAgGridFilterDef(gridOptions: GridOptions, fieldName: string): Maybe<FilterViewDef> {
  return findAgGridColumnDef(gridOptions, fieldName)?.filterParams.viewDef
}

export function getGridOptionsWrapper(gridApi: GridApi): GridOptionsWrapper {
  return _.get(gridApi, 'gridOptionsWrapper')
}

export interface AgGridContextMenuFeature {
  /** 是否需要选中行 */
  requireSelection?: boolean,
  /** 是否需要权限, 有其中一个权限即可 */
  requirePermissions?: string[],
  /** 需要权限或是责任人 */
  requirePermissionsOrOwner?: string[],
  /**
   * 自定义检查函数, 传入当前rowNode.data判断. 支持多选的话, 在action中判断并给出阻止理由.
   * 返回值直接给到`disabled`, 可用返回false, 不可用返回true
   */
  customCheckFunc?: (data: any) => boolean
}

/**
 * 检查AgGrid右键菜单项是否可用
 * @param rowNode
 * @param vue
 * @param menuItemFeature
 * @param ownerField 责任人字段, 用于检查是否是自己的数据, 如:'applicant.id'
 */
export function checkMenuAccessibility(rowNode: RowNode,
                                       vue: Vue,
                                       menuItemFeature: AgGridContextMenuFeature,
                                       ownerField: string = null) {
  if (menuItemFeature.requireSelection && (_.isNil(rowNode) || rowNode.group)) return true
  let hasPermission = _.isEmpty(menuItemFeature.requirePermissions) &&
          _.isEmpty(menuItemFeature.requirePermissionsOrOwner) // 没有权限要求, 默认可用
  if (!_.isEmpty(menuItemFeature.requirePermissions)) {
    for (const permissionCode of menuItemFeature.requirePermissions) {
      if (vue.$perm(permissionCode)) {
        hasPermission = true
        break
      }
    }
  }
  if (!_.isEmpty(menuItemFeature.requirePermissionsOrOwner)) {
    // 判断是否责任人
    if (!_.isNil(ownerField)) {
      let owner = _.toString(_.get(rowNode?.data, ownerField))
      // 从audit字段中提取userId
      if (owner?.indexOf('-') > 0) {
        owner = owner.split('-')[0]
      }
      if (owner === LocalDbDao.getPrincipal().identity) {
        hasPermission = true
      }
    }
    if (!hasPermission) {
      for (const permissionCode of menuItemFeature.requirePermissionsOrOwner) {
        if (vue.$perm(permissionCode)) {
          hasPermission = true
          break
        }
      }
    }
  }
  if (menuItemFeature.customCheckFunc) {
    return menuItemFeature.customCheckFunc(rowNode?.data) || !hasPermission
  } else {
    return !hasPermission
  }
}

export function patchMultiSelectOnContextMenu(rowNode: RowNode,
                                              explorer: { restoreSelection: () => void },
                                              selectedData: any[]) {
  if (!rowNode || !_.isEmpty(selectedData)) return
  selectedData.push(rowNode.data)
  explorer.restoreSelection()
}
