export default [
  {
    path: '/atd/attendance-import',
    name: 'PageAttendanceImport',
    meta: {
      title: '考勤导入',
      auth: true
    },
    component: () => import('./views/page-atd-attendance-import.vue')
  },
  {
    path: '/atd/attendance',
    name: 'PageAttendance',
    meta: {
      title: '考勤记录管理',
      auth: true
    },
    component: () => import('./views/page-atd-attendance.vue')
  },
  {
    path: '/atd/leaving',
    name: 'PageLeaving',
    meta: {
      title: '请假管理',
      auth: true
    },
    component: () => import('./views/page-atd-leaving.vue')
  },
  {
    path: '/atd/shift',
    name: 'PageShift',
    meta: {
      title: '班次管理',
      auth: true
    },
    component: () => import('./views/page-atd-shift.vue')
  }
]
