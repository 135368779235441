import _ from 'lodash'

const i18n = {
  byId: '按ID',
  inIds: '按ID范围',
  isNull: '为空',
  isNotNull: '不为空',
  string: '文本',
  number: '数字',
  date: '日期',
  bool: '是否',
  array: '数组',
  vEnum: '枚举',
  custom: '自定义',
  and: '并且',
  or: '或者',

  equalIgnoreCase: '{}等于',
  equals: '{}等于',
  contains: '内容{}含有',
  like: '{}部分含有',
  startsWith: '{}以…起始',
  endsWith: '{}以…结束',
  regex: '{}匹配正则',
  empty: '{}为空',
  in: '{}包含在(in)',
  lt: '{}小于',
  lte: '{}小于或等于',
  gt: '{}大于',
  gte: '{}大于或等于',
  gtNow: '{}晚于当前',
  ltNow: '{}早于当前',
  between: '[]范围',
  betweenDate: '[]日期范围',
  byDay: '[]今天',
  byWeek: '[]本周',
  byMonth: '[]本月',
  byQuarter: '[]本季度',
  byYear: '[]本年度',
  yes: '是',
  no: '否',
  true: '是',
  false: '否',
  all: '全部',
  unknown: '未知',

  not: '不',
  notIn: '不在',

  keyEquals: '编号{}等于',
  keyContains: '编号{}含有',
  keyStartsWith: '编号{}以…起始',
  keyEndsWith: '编号{}以…结束',
  valueEquals: '值{}等于',
  valueContains: '值{}含有',
  valueStartsWith: '值{}以…起始',
  valueEndsWith: '值{}以…结束'
}

export default function (key, not) {
  return (i18n[key] || _.toString(key) || '未知')
    .replace('{}', (not ? i18n.not : ''))
    .replace('[]', (not ? i18n.notIn : ''))
}
